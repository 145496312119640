import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ReloadIcon: FC<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M4 2.00098C4 1.44869 3.55228 1.00098 3 1.00098C2.44772 1.00098 2 1.44869 2 2.00098V8.00098C2 8.00117 2 8.00136 2 8.00155C1.99992 8.24002 2.08463 8.47933 2.25671 8.67054C2.46255 8.89924 2.74996 9.01086 3.03515 9.00098H9C9.55228 9.00098 10 8.55326 10 8.00098C10 7.44869 9.55228 7.00098 9 7.00098H5.60662L6.66668 6.04693L6.66777 6.04595C7.8182 5.01735 9.24159 4.34344 10.7664 4.10546C12.2916 3.86741 13.8532 4.07574 15.2627 4.70532C16.6722 5.33489 17.8694 6.35883 18.71 7.65363C19.5506 8.94842 19.9986 10.4588 20 12.0025C20.0005 12.5548 20.4486 13.0021 21.0009 13.0016C21.5532 13.0011 22.0005 12.5529 22 12.0007C21.9982 10.071 21.4382 8.18308 20.3875 6.56459C19.3368 4.9461 17.8402 3.66617 16.0783 2.8792C14.3165 2.09223 12.3645 1.83182 10.4579 2.12939C8.55137 2.42695 6.77161 3.26978 5.33334 4.55621L4 5.75622V2.00098Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4 11.9991C3.9995 11.4468 3.55137 10.9995 2.99909 11C2.4468 11.0005 1.9995 11.4486 2 12.0009C2.00176 13.9306 2.56178 15.8185 3.61251 17.437C4.66324 19.0555 6.1598 20.3354 7.92168 21.1224C9.68356 21.9093 11.6355 22.1698 13.5421 21.8722C15.4486 21.5746 17.2284 20.7318 18.6667 19.4454L18.669 19.4433L20 18.2454V21.999C20 22.5513 20.4477 22.999 21 22.999C21.5523 22.999 22 22.5513 22 21.999V16.0038C22 15.9991 22 15.9943 22 15.9896C21.9976 15.7546 21.9129 15.5194 21.7433 15.331C21.3738 14.9205 20.7415 14.8872 20.331 15.2567L18.3951 16.999L17.3333 17.9546L17.3322 17.9556C16.1818 18.9842 14.7584 19.6581 13.2337 19.8961C11.7084 20.1342 10.1468 19.9258 8.73734 19.2963C7.32784 18.6667 6.13059 17.6427 5.29 16.3479C4.44942 15.0532 4.00141 13.5428 4 11.9991Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.99951 12.001C9.99951 10.8964 10.8949 10.001 11.9995 10.001C13.1041 10.001 13.9995 10.8964 13.9995 12.001C13.9995 13.1055 13.1041 14.001 11.9995 14.001C10.8949 14.001 9.99951 13.1055 9.99951 12.001Z" fill="currentcolor" />
  </Svg>
);
