import { forwardRef } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const QuoteIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => (
  <Svg
    ref={ref}
    direction="top"
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M4.18996 5.29958C3.28263 6.46267 3.15172 7.63489 3.41831 8.45507C4.42579 7.6346 5.82595 7.8064 6.67855 8.62322C7.53981 9.44836 7.61391 10.8988 7.06438 11.8381C6.6021 12.6282 5.79028 13.1042 4.88446 13.1042C2.82297 13.1042 1.875 11.2396 1.875 9.14749C1.875 7.78901 2.21259 6.57233 2.88779 5.49742C3.56299 4.42251 4.58221 3.55533 5.94547 2.89587L6.31201 3.62787C5.48891 3.98397 4.78157 4.54121 4.18996 5.29958ZM10.9034 5.29958C9.99603 6.46267 9.86511 7.63489 10.1317 8.45507C10.5818 8.09896 11.0705 7.9209 11.5978 7.9209C12.9849 7.9209 14.125 8.86826 14.125 10.5126C14.125 12.0246 12.9935 13.1042 11.5978 13.1042C9.53636 13.1042 8.58838 11.2396 8.58838 9.14749C8.58838 7.78902 8.92598 6.57233 9.60117 5.49742C10.2764 4.42251 11.2956 3.55533 12.6589 2.89587L13.0254 3.62786C12.2023 3.98397 11.4949 4.54121 10.9034 5.29958Z"
    />
  </Svg>
));
